<template>
  <b-card no-body>
    <!-- <b-card-header class="pb-50">
          <h5>
            {{ $t("Filters") }}
          </h5>
        </b-card-header> -->
    <b-card-body>
      <b-row class="mb-75">
        <b-col cols="12" md="12" class="mb-md-0 mb-2">
          <label>{{ $t("Staff") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="staffAssign"
            :options="staffAssignOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:staffAssign', val)"
            :disabled="checkAssignButtonStatus()"
          />
        </b-col>
      </b-row>
      <b-row class="mb-75">
        <b-col cols="12" md="12" class="mb-md-0 mb-2">
          <label>{{ $t("Assign Type") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="assignType"
            :options="assignTypeOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:assignType', val)"
            :disabled="checkAssignButtonStatus()"
          />
        </b-col>
      </b-row>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-75"
        block
        @click="assignTheCustomers"
        :disabled="checkAssignButtonStatus() || staffAssign == null || assignType == null"
      >
        {{ $t("Modules.Customer.Label.Assign") }}
      </b-button>
      <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        class="mb-75"
        block
        :disabled=checkAssignButtonStatus()
      >
        {{ $t("Modules.Customer.Label.Assign all customers to Staff") }}
      </b-button> -->
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="warning"
        class="mb-75"
        block
        @click="unassignTheCustomers"
        :disabled="checkUnassignButtonStatus() || staffFilter == null || staffFilter == 'not_assigned'"
      >
        {{ $t("Modules.Customer.Label.Unassign") }}
      </b-button>
      <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="danger"
        class="mb-75"
        block
        :disabled=checkUnassignButtonStatus()
      >
        {{ $t("Modules.Customer.Label.Unassign all customers") }}
      </b-button> -->
      <b-alert variant="primary" show>
        <div class="alert-body">
          <span>
            {{ $t("Modules.Customer.Message.Assign Note 1") }}
          </span>
        </div>
      </b-alert>
      <b-alert variant="primary" show>
        <div class="alert-body">
          <span>
            {{ $t("Modules.Customer.Message.Assign Note 2") }}
          </span>
        </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BAlert,
    vSelect,
  },
  props: {
    staffAssignOptions: {
      type: Array,
      required: true,
    },
    staffAssign: {
      type: [String, null],
      default: null,
    },
    totalCustomers: {
      type: Number,
      required: true,
    },
    staffFilter: {
      type: [String, null],
      default: null,
    },
    assignTypeOptions: {
      type: Array,
      required: true,
    },
    assignType: {
      type: [String, null],
      default: null,
    },
  },
  setup(props, context) {
    const vm = context.root;

    //Check button status
    const checkAssignButtonStatus = () => {
      if (props.totalCustomers > 0) return false;
      return true;
    };
    const checkUnassignButtonStatus = () => {
      if (props.totalCustomers > 0) return false;
      return true;
    };

    //Assign the customers
    const assignTheCustomers = async () => {
      if (await vm.showConfirmDialog()) {
        context.emit("assign-the-customers");
      }
    };

    //Unassign the customers
    const unassignTheCustomers = async () => {
      if (await vm.showConfirmDialog()) {
        context.emit("unassign-the-customers");
      }
    };

    return {
      //Function
      checkAssignButtonStatus,
      checkUnassignButtonStatus,
      assignTheCustomers,
      unassignTheCustomers
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
