import axios from 'axios'

export default class CustomerRepository {
  static list(queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/customers', { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static create(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/customers', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  // static view(id) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get('/api/users/' + id)
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   })
  // }
  static update(data) {
    return new Promise((resolve, reject) => {
      axios
        .put('/api/customers/' + data.id, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  // static delete(id) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .delete('/api/users/' + id)
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   })
  // }
  static import(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/customers/import', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static assignTheCustomers(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/customers/assign-the-customers', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static unassignTheCustomers(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/customers/unassign-the-customers', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}