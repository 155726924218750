<template>
  <div>
    <customer-list-add-new
      :is-add-new-customer-sidebar-active.sync="isAddNewCustomerSidebarActive"
      :state-options="stateOptions"
      :phone-type-options="phoneTypeOptions"
      :phone-status-options="phoneStatusOptions"
      :form-action="formAction"
      :customer-data-update="customerDataUpdate"
      @refetch-data="refetchData"
    />
    <b-row>
      <b-col cols="12" md="10" class="mb-md-0 mb-2">
        <!-- Filters -->
        <customers-list-filters
          :state-filter.sync="stateFilter"
          :phone-type-filter.sync="phoneTypeFilter"
          :phone-status-filter.sync="phoneStatusFilter"
          :staff-filter.sync="staffFilter"
          :state-options="stateOptions"
          :phone-type-options="phoneTypeOptions"
          :phone-status-options="phoneStatusOptions"
          :staff-filter-options="staffFilterOptions"
        />
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="5"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t("Show") }}</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ $t("entries") }}</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="7">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    @keyup.enter="refetchData"
                    @keyup.delete="searchQuery == '' ? refetchData() : ''"
                    class="d-inline-block mr-1"
                    :placeholder="$t('Search') + '...'"
                  />
                  <b-button variant="info" @click="searchQuery != '' ? refetchData() : ''" class="btn-icon mr-1">
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                  <b-button variant="danger" @click="clearFilter()" class="btn-icon mr-1">
                    <feather-icon icon="XIcon" />
                  </b-button>
                  <b-button variant="primary" @click="add()">
                    <span class="text-nowrap">{{
                      $t("Modules.Customer.Label.Add Customer")
                    }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{
                  $t("Showing entries", {
                    from: dataMeta.from,
                    to: dataMeta.to,
                    of: dataMeta.of,
                    of: dataMeta.of,
                  })
                }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCustomers"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refCustomerListTable"
            class="position-relative"
            :items="fetchCustomers"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            :empty-text="$t('No matching records found')"
            :sort-desc.sync="isSortDirDesc"
            sticky-header="480px"
          >
            <!-- Column: Customer -->
            <template #cell(customer)="data">
              <b-media vertical-align="center">
                <b-link
                  class="font-weight-bold d-block text-nowrap customer-name-short"
                  @click="edit(data.item)"
                >
                  {{
                    (data.item.gender
                      ? $t("Modules.Customer.Label." + data.item.gender)
                      : "") + data.item.name
                  }}
                </b-link>
              </b-media>
            </template>

            <!-- Column: State -->
            <template #cell(state)="data">
              <div class="text-nowrap">
                <span>{{ data.item.state.stateLabel }}</span>
              </div>
            </template>

            <!-- Column: Type -->
            <template #cell(type)="data">
              <div class="text-nowrap">
                <span>{{
                  $t("Modules.Customer.Label." + data.item.type)
                }}</span>
              </div>
            </template>

            <!-- Column: Type -->
            <template #cell(status)="data">
              <div class="text-nowrap">
                <span>{{
                  $t(data.item.status)
                }}</span>
              </div>
            </template>

            <!-- Column: Staff -->
            <template #cell(staff)="data">
              <div v-html="printStaffs(data.item)">
              </div>
            </template>

            <!-- Column: Last Call Time -->
            <template #cell(lastCallTime)="data">
              <div class="text-nowrap" v-html="formatTimeZone(data.item.lastCallTime)">
              </div>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!-- <b-dropdown-item :to="{ name: 'customers-view', params: { id: data.item.id } }">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">{{ $t('Details') }}</span>
                </b-dropdown-item> -->

                <b-dropdown-item @click="edit(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                </b-dropdown-item>

                <!-- <b-dropdown-item v-show="data.item.role != 'Admin'">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50" @click="delete data.item">{{
                    $t("Delete")
                  }}</span>
                </b-dropdown-item> -->

              </b-dropdown>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col cols="12" md="2" class="mb-md-0 mb-2">
        <!-- Assign -->
        <customers-assign
          :staff-assign-options="staffAssignOptions"
          :staff-assign.sync="staffAssign"
          :staff-filter.sync="staffFilter"
          :assign-type.sync="assignType"
          :assign-type-options="assignTypeOptions"
          :total-customers.sync="totalCustomers"
          @assign-the-customers="assignTheCustomers"
          @unassign-the-customers="unassignTheCustomers"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, watch } from "@vue/composition-api";
import customersListFilters from "./customersListFilters.vue";
import customersList from "./customersList";
import customerListAddNew from "./customerListAddNew.vue";
import customersAssign from "./customersAssign.vue";
import CustomerRepository from "@/modules/customer/repository/customerRepository";
import { mainDB } from "@/database/mainDB";

export default {
  components: {
    customersListFilters,
    customerListAddNew,
    customersAssign,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup(props, context) {
    const vm = context.root;
    const isAddNewCustomerSidebarActive = ref(false);
    var formAction = {
      action: "create",
      label: vm.$i18n.t("Modules.Customer.Label.Add Customer"),
      button: vm.$i18n.t("Add"),
    };
    var customerDataUpdate = {};
    vm.disableCenterLoading();

    const phoneTypeOptions = [
      { label: vm.$i18n.t("work_phone"), value: "work_phone" },
      { label: vm.$i18n.t("cell_phone"), value: "cell_phone" },
    ];

    const phoneStatusOptions = [
      { label: vm.$i18n.t("cold_call"), value: "cold_call" },
      { label: vm.$i18n.t("potential"), value: "potential" },
      { label: vm.$i18n.t("hothit"), value: "hothit" },
      { label: vm.$i18n.t("denied"), value: "denied" },
      { label: vm.$i18n.t("closed_case"), value: "closed_case" },
    ];

    const assignTypeOptions = [
      { label: vm.$i18n.t("auto"), value: "auto" },
      { label: vm.$i18n.t("cold_call"), value: "cold_call" },
      { label: vm.$i18n.t("potential"), value: "potential" },
      { label: vm.$i18n.t("hothit"), value: "hothit" },
      { label: vm.$i18n.t("denied"), value: "denied" },
      { label: vm.$i18n.t("closed_case"), value: "closed_case" },
    ]

    // States
    const stateOptions = ref([]);
    mainDB.states
      .orderBy("stateName")
      .each((state) =>
        stateOptions.value.push({ label: state.stateLabel, value: state.id })
      );

    // Staffs
    const staffOptions = ref([]);
    const staffAssignOptions = ref([]);
    const staffFilterOptions = ref([{ label: vm.$i18n.t('Modules.Customer.Label.Not Assigned'), value: 'not_assigned' }]);
    mainDB.staffs
      .orderBy("shortName")
      .each((staff) => {
        staffOptions.value.push({ label: staff.shortName, value: staff.id })
        staffAssignOptions.value.push({ label: staff.shortName, value: staff.id })
        staffFilterOptions.value.push({ label: staff.shortName, value: staff.id })
      });

    //StaffAssign
    const staffAssign = ref(null);
    const assignType = ref(null);

    const {
      customerItems,
      fetchCustomers,
      tableColumns,
      perPage,
      currentPage,
      totalCustomers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerListTable,
      refetchData,

      // Extra Filters
      stateFilter,
      phoneTypeFilter,
      phoneStatusFilter,
      staffFilter,
    } = customersList();

    // AssignTheCustomers
    const assignTheCustomers = () => {
      CustomerRepository
      .assignTheCustomers({
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        state: stateFilter.value,
        phoneType: phoneTypeFilter.value,
        phoneStatus: phoneStatusFilter.value,
        staff: staffFilter.value,
        staffAssign: staffAssign.value,
        assignType: assignType.value
      })
      .then(() => {
        staffAssign.value = null
        assignType.value = null
        refetchData()
      })
    }

    // UnassignTheCustomers
    const unassignTheCustomers = () => {
      CustomerRepository
      .unassignTheCustomers({
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        state: stateFilter.value,
        phoneType: phoneTypeFilter.value,
        phoneStatus: phoneStatusFilter.value,
        staff: staffFilter.value
      })
      .then(() => {
        refetchData()
      })
    }

    const printStaffs = (item) => {
      var staffs = '';
      if(item.coldcallStaff) staffs += '<div>' + item.coldcallStaff.staff + '</div>'
      if(item.potentialStaff) staffs += '<div>' + item.potentialStaff.staff + '</div>'
      if(item.hothitStaff) staffs += '<div>' + item.hothitStaff.staff + '</div>'
      if(item.deniedStaff) staffs += '<div>' + item.deniedStaff.staff + '</div>'
      if(item.closedCaseStaff) staffs += '<div>' + item.closedCaseStaff.staff + '</div>'
      return staffs
    }

    const clearFilter = () => {
      stateFilter.value = null
      phoneTypeFilter.value = null
      phoneStatusFilter.value = null
      staffFilter.value = null
      assignType.value = null
      searchQuery.value = null
    }

    const view = (item) => {

    }

    // watch([staffFilter], () => {
    //   staffAssignOptions.value = vm.removeItemFromArrayOfObject(staffOptions.value, 'value', staffFilter);
    // })

    return {
      // Sidebar
      isAddNewCustomerSidebarActive,

      fetchCustomers,
      customerItems,
      tableColumns,
      perPage,
      currentPage,
      totalCustomers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerListTable,
      refetchData,
      stateOptions,
      phoneTypeOptions,
      phoneStatusOptions,
      staffOptions,
      staffAssignOptions,
      staffFilterOptions,
      formAction,
      customerDataUpdate,
      clearFilter,

      // Extra Filters
      stateFilter,
      phoneTypeFilter,
      phoneStatusFilter,
      staffFilter,
      staffAssign,
      assignType,
      assignTypeOptions,

      //Function
      assignTheCustomers,
      unassignTheCustomers,
      printStaffs,
      view
    };
  },
  methods: {
    async delete(item) {
      const vm = this;
      vm.enableStillLoading();
      if (await vm.showDeleteConfirmDialog()) {
        CustomerRepository.delete(item.id).then(() => {
          vm.disableStillLoading();
          vm.refetchData();
        });
      }
    },
    add() {
      const vm = this;
      vm.formAction = {
        action: "create",
        label: vm.$i18n.t("Modules.Customer.Label.Add Customer"),
        button: vm.$i18n.t("Add"),
      };
      vm.isAddNewCustomerSidebarActive = true;
      vm.customerDataUpdate = {};
    },
    edit(item) {
      const vm = this;
      vm.formAction = {
        action: "update",
        label: vm.$i18n.t("Modules.Customer.Label.Update Customer"),
        button: vm.$i18n.t("Save"),
      };
      vm.isAddNewCustomerSidebarActive = true;
      vm.customerDataUpdate = item;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
