import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import CustomerRepository from '@/modules/customer/repository/customerRepository'
import i18n from '@/libs/i18n'

export default function useCustomersList() {
  // Use toast
  const toast = useToast()

  const refCustomerListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'customer', sortable: true, label: i18n.t('Modules.Customer.Label.Customer') },
    { key: 'state', sortable: false, label: i18n.t('State') },
    { key: 'type', sortable: false, label: i18n.t('Phone Type') },
    { key: 'status', sortable: false, label: i18n.t('Status') },
    { key: 'staff', sortable: false, label: i18n.t('Modules.Customer.Label.Staff') },
    { key: 'lastCallTime', sortable: true, label: i18n.t('Modules.Customer.Label.Date Time') },
    { key: 'actions', label: i18n.t('Actions') }
  ]
  const perPage = ref(100)
  const totalCustomers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 20, 50, 100, 200, 500]
  const searchQuery = ref('')
  const sortBy = ref('lastCallTime')
  const isSortDirDesc = ref(false)
  const stateFilter = ref(null)
  const phoneTypeFilter = ref(null)
  const phoneStatusFilter = ref(null)
  const staffFilter = ref(null)
  const customerItems = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refCustomerListTable.value ? refCustomerListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCustomers.value,
    }
  })

  const refetchData = () => {
    refCustomerListTable.value.refresh()
  }

  watch([currentPage, perPage, stateFilter, phoneTypeFilter, phoneStatusFilter, staffFilter], () => {
    refetchData()
  })

  const fetchCustomers = (ctx, callback) => {
    CustomerRepository
      .list({
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        state: stateFilter.value,
        phoneType: phoneTypeFilter.value,
        phoneStatus: phoneStatusFilter.value,
        staff: staffFilter.value
      })
      .then(response => {
        const { customers, meta } = response.data.data
        callback(customers)
        totalCustomers.value = meta.total
        customerItems.value = customers
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    customerItems,
    fetchCustomers,
    tableColumns,
    perPage,
    currentPage,
    totalCustomers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCustomerListTable,
    refetchData,
    // Extra Filters
    stateFilter,
    phoneTypeFilter,
    phoneStatusFilter,
    staffFilter
  }
}
