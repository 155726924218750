<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("State") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="stateFilter"
            :options="stateOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:stateFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Phone Type") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="phoneTypeFilter"
            :options="phoneTypeOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:phoneTypeFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Status") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="phoneStatusFilter"
            :options="phoneStatusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:phoneStatusFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Staff") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="staffFilter"
            :options="staffFilterOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:staffFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    stateFilter: {
      type: [String, null],
      default: null,
    },
    phoneTypeFilter: {
      type: [String, null],
      default: null,
    },
    phoneStatusFilter: {
      type: [String, null],
      default: null,
    },
    staffFilter: {
      type: [String, null],
      default: null,
    },
    stateOptions: {
      type: Array,
      required: true,
    },
    phoneTypeOptions: {
      type: Array,
      required: true,
    },
    phoneStatusOptions: {
      type: Array,
      required: true,
    },
    staffFilterOptions: {
      type: Array,
      required: true,
    },
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
